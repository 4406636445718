// define all UI variable
const navToggler = document.querySelector(".nav-toggler");
const navMenu = document.querySelector(".site-navbar ul");
const navLinks = document.querySelectorAll(".site-navbar a");
const body = document.querySelector("body");

// load all event listners
allEventListners();

// functions of all event listners
function allEventListners() {
  // toggler icon click event
  navToggler.addEventListener("click", togglerClick);
  // nav links click event
  navLinks.forEach((elem) => elem.addEventListener("click", navLinkClick));
}

// togglerClick function
function togglerClick() {
  navToggler.classList.toggle("toggler-open");
  navMenu.classList.toggle("open");
  body.classList.toggle("navbar-open");
}

// navLinkClick function
function navLinkClick(e) {
  navLinks.forEach((elem) => elem.classList.remove("active"));
  body.classList.toggle("navbar-open");
  e.target.classList.add("active");
  if (navMenu.classList.contains("open")) {
    navToggler.click();
  }
}
